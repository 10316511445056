<template>
  <div id="AV">
    <transition name="load">
      <div class="loading" v-if="loading" key="1">
        <img src="../../assets/loading.gif" alt="" />
      </div>

      <div v-else key="2">
        <carousel
          autoplay="true"
          loop="true"
          paginationPosition="bottom-overlay"
          easing="ease"
          autoplayTimeout="3000"
          perPage="1"
          :paginationSize="size"
          paginationColor="rgb(223, 223, 223)"
          paginationActiveColor="#006837"
          mouseDrag="true"
        >
          <slide v-for="(item, index) in data" :key="index">
            <div class="AV-up" v-on:click="detail(item.judul)">
              <img :src="item.foto" alt="" />
              <div class="AV-top">
                <h2>Artikel Pilihan</h2>

                <div class="AV-top-desc">
                  <h2>{{ item.judul }}</h2>
                  <p class="AV-desc-h">{{ item.tanggal }}</p>
                  <p class="AV-desc-m"><a>Baca >>></a></p>
                </div>
              </div>
            </div>
          </slide>
        </carousel>

        <div class="AV-inner">
          <div class="AV-late">
            <h1>Artikel Terbaru</h1>
            <div class="AV-late-det">
              <div
                class="AV-late-post"
                v-for="(item, index) in dataLatest"
                :key="index"
              >
                <img :src="item.foto" alt="" />
                <h3>{{ item.judul }}</h3>
                <div class="AV-post-p">
                  <p>{{ item.tanggal }}</p>
                  <p><a v-on:click="detail(item.judul)">Baca >>></a></p>
                </div>
              </div>
            </div>
          </div>

          <div class="AV-other">
            <h3>Artikel Lainnya</h3>
            <div class="AV-other-container">
              <div
                class="AV-other-post"
                v-for="(item, index) in dataSamping"
                :key="index"
              >
                <h4>{{ item.judul }}</h4>
                <p class="AV-other-h">{{ item.tanggal }}</p>
                <p><a v-on:click="detail(item.judul)">Baca >>></a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import konten from "../../services/konten.service";
import moment from "moment";

export default {
  name: "Artikel",
  data() {
    return {
      loading: true,
      size: 12,
      id: [],
      data: [],
      dataLatest: [],
      dataSamping: [],
    };
  },
  methods: {
    get() {
      console.log(this.id);
      konten
        .getLimit("artikel", this.id, 3)
        .then((res) => {
          this.data = res.data.data;
          for (var i in this.data) {
            moment.locale("id");
            this.data[i].tanggal = moment(
              new Date(this.data[i].tanggal)
            ).fromNow();
          }

          /** latest */
          konten
            .getLatest("artikel", 4)
            .then((res) => {
              this.dataLatest = res.data.data;
              for (var i in this.dataLatest) {
                moment.locale("id");
                this.dataLatest[i].tanggal = moment(
                  new Date(this.dataLatest[i].tanggal)
                ).fromNow();
                this.id.push(this.dataLatest[i].id);

                /** other */
                konten
                  .getLimit("artikel", this.id, "all")
                  .then((res) => {
                    this.dataSamping = res.data.data;
                    for (var i in this.dataSamping) {
                      moment.locale("id");
                      this.dataSamping[i].tanggal = moment(
                        new Date(this.dataSamping[i].tanggal)
                      ).fromNow();

                      this.loading = false;
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    detail(judul) {
      judul = judul.toLowerCase().replace(/[^a-zA-Z ]/g, "");
      judul = judul.replace(/ /g, "_");
      console.log(judul);
      this.$router.push("/artikel/" + judul);
    },
    onResize() {
      console.log(console.log('ayam'))
      if (window.innerWidth > 880) {
        this.size = 17.5;
      } else {
        this.size = 10;
      }
    }
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  async mounted() {
    await this.get();
  },
  
};
</script>

<style scoped>
#AV {
  width: 100%;
  padding-top: 65px;
}

.load-enter-active,
.load-leave-active {
  transition: opacity 0.5s ease-in;
}

.load-enter,
.load-leave-to {
  opacity: 0;
}

.loading {
  position: fixed; /* or absolute */
  margin-top: -64px;
  margin-left: -64px;
  top: 50%;
  left: 50%;
  transition: display 0.5ms ease;
}

.loading img {
  max-width: 128px;
}
.AV-up {
  width: 100vw;
  height: 300px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 2fr 1fr;
  cursor: pointer;
  /* margin-bottom: 2em; */
}
.AV-up img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.AV-top {
  width: 100%;
  height: 100%;
  background: #248842;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em;
}
.AV-up h2 {
  font-size: 1.5rem;
  font-weight: 400;
  color: white;
}
.AV-top-desc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  color: white;
}
.AV-top-desc h2 {
  grid-column: 1/3;
  grid-column: 1/3;
  grid-row: 1/2;
  font-weight: 400;
}
.AV-desc-h {
  grid-column: 1/2;
  grid-row: 2/3;
  display: flex;
  align-items: flex-end;
}
.AV-desc-m {
  grid-column: 2/3;
  grid-row: 2/3;
  display: flex;
  align-items: flex-end;
}
.AV-desc-m a {
  text-decoration: none;
  color: white;
}

@media only screen and (max-width: 900px) {
  .AV-up {
    height: 50vh;
    grid-template-rows: 30vh 20vh;
    grid-template-columns: auto;
  }

  .AV-up img {
    height: 50vh;
    width: 100vw;
  }

  .AV-top {
    height: 20vh;
    background: #248842ce;
    flex-direction: row;
  }

  .AV-top h2 {
    display: none;
  }

  .AV-top-desc h2 {
    display: block;
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 680px) {
  .AV-top-desc h2 {
    display: block;
    font-size: 1.25rem;
  }
}

.AV-inner {
  width: 95%;
  margin: 0 auto;
  margin-top: 2em;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-gap: 2em !important;
  max-width: 2000px;
}
.AV-late h1 {
  font-weight: 400;
  width: 100%;
  border-bottom: 1.5px solid #248842;
  color: #248842;
}
.AV-late-det {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  flex-wrap: wrap;
}
.AV-late-post {
  width: 100%;
  min-width: 180px;
  max-width: 15vw;
  margin-bottom: 1em;
}

.AV-late-post img {
  width: 100%;
  height: 15vh;
  object-fit: cover;
  border: green solid 2px;
}
.AV-late-post h3 {
  color: #248842;
  font-size: 1rem;
  margin: 0.5em 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.AV-post-p {
  display: flex;
  justify-content: space-between;
  color: #808080;
  font-size: 0.9rem;
}
.AV-post-p a {
  color: #808080;
  text-decoration: none;
  cursor: pointer;
}
.AV-other {
  height: 25vh;
  left: 77.5%;
}
.AV-other-container {
  height: 45vh;
  overflow: auto;
}
.AV-other h3 {
  font-weight: 400;
  margin-bottom: 0.75em;
  color: #248842;
}
.AV-other-post {
  display: grid;
  grid-template-columns: auto 5vw;
  grid-template-rows: auto auto;
  grid-column-gap: 0.75em;
  grid-row-gap: 0.25em;
  margin-bottom: 0.9em;
}
.AV-other-post h4 {
  grid-column: 1/2;
  grid-row: 1/2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #248842;
}
.AV-other-post p {
  grid-column: 2/3;
  grid-row: 1/3;
}
.AV-other-post .AV-other-h {
  grid-column: 1/2;
  grid-row: 2/3;
  color: #808080;
  font-size: 0.9rem;
}
.AV-other-post a {
  color: #808080;
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;
}
@media screen and (max-width: 680px) {
  .AV-inner {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    margin-top: 1em;
  }

  .AV-late {
    width: 100%;
  }

  .AV-late h1 {
    font-size: 1.25em;
    font-weight: 400;
  }

  .AV-late-post h3 {
    font-size: 0.85rem;
  }

  .AV-post-p {
    font-size: 0.7rem;
  }

  .AV-other-post {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    grid-column-gap: 0.75em;
    grid-row-gap: 0.25em;
    margin-bottom: 0.9em;
  }
  .AV-other-post h4 {
    grid-column: 1/4;
    grid-row: 1/2;
  }
  .AV-other-post p {
    grid-column: 2/4;
    grid-row: 2/3;
    text-align: left;
  }
  .AV-other-post .AV-other-h {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .AV-other h3 {
    font-size: 1.25rem;
    border-bottom: 1.5px solid #248842;
  }

  .AV-other-post h4 {
    font-size: 0.85rem;
    font-weight: 400;
  }

  .AV-other-post a {
    font-size: 0.75rem;
  }

  .AV-other-post .AV-other-h {
    color: #808080;
    font-size: 0.75rem;
  }
}
</style>
